import React, { useState, useEffect } from "react";
import { getPlayerSpotlights } from "../../../../../models/Player/Spotlights";

import { SpotlightContext } from "./SpotlightContext";
import SpotlightTable from "./SpotlightTable"
import SpotlightFilters from "./SpotlightFilters"
import StarIcon from '@mui/icons-material/Star';
import IMLoader from '../../../../common/IMLoader';

const SpotlightTab = ({styles, iconColor}) =>{
  return(
      <div style={styles}>
        <StarIcon color={iconColor}/>
        <p style={{margin: 0}}>SPOTLIGHT</p>
      </div>
    )
}

const SpotlightContainer = () => {
  const [shownSpotlights, setShownSpotlights] = useState([])
  const [originSpotlights, setOriginSpotlights] = useState([])
  const [loading, setLoading] = useState(true)
  const [showNextSpotlights, setShowNextSpotlights] = useState(false)

  useEffect(()=>{
    const playerId = window.location.pathname.split('/')[2]
    getPlayerSpotlights(playerId).then((spotlights)=>{
      setInitialStates(spotlights)
      setLoading(false)
    }).catch((error)=>{
      console.error(error)
      setLoading(false)
    })

  },[])

  function setInitialStates(spotlights){
    setOriginSpotlights(spotlights)
    setShownSpotlights(spotlights)
  }

  const ltBlue = '#1976D2'

  const tabStyles = {
    marginBottom: '20px',
    width: '10%',
    display: 'flex',
    height: '35px',
    lineHeight: '27px',
    borderBottom: `1px solid ${ltBlue}`,
    textAlign: 'center',
    justifyContent: 'center',
    gap: '5px',
    color: ltBlue,
  }

  const noContentStyles = {
    fontSize: 20,
    color: 'grey',
    width: 'inherit',
    textAlign: 'center',
    fontStyle: 'italic',
    marginTop: 20
  }

  return (
    <>
      { loading &&
          <IMLoader message={"Loading Spotlights..."} mini={true}/>
      }
      {
        originSpotlights.length > 0 && !loading &&
        <SpotlightContext.Provider value={{
          shownSpotlights,
          originSpotlights,
          setShownSpotlights,
          setOriginSpotlights,
          showNextSpotlights,
          setShowNextSpotlights
        }}>
          <SpotlightTab styles={tabStyles} iconColor={ltBlue}/>
          <SpotlightFilters/>
          <SpotlightTable/>
        </SpotlightContext.Provider>
      }
      {
        originSpotlights.length === 0 && !loading &&
        <div style={noContentStyles}>No Spotlights to Display</div>
      }
    </>

  );
};

export default SpotlightContainer;