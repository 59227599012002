import React, {useContext, useState } from 'react';
import {
  TextField,
  Button
} from '@mui/material';
import { styled } from '@mui/material/styles';
import {buttonClasses} from "@mui/material/Button";
import {SupportModalContext} from "./SupportModalContext";
import {addIapValue} from "../../../../../api/Challenge"
import IMAlerts from "../../../../common/IMAlerts";

const IapValueForm = (props) => {
  const {group, setGroup} = useContext(SupportModalContext)
  const [value, setValue] = useState('');
  const [buttonDisabled, setButtonDisabled] = useState(true)
  const [buttonState, setButtonState] = useState("enabled")
  const [alertOpen, setAlertOpen] = useState(false)

  const iapTotalSpend = props.iapTotalSpend
  const setIapTotalSpend = props.setIapTotalSpend

  const iapActivity = group.activities.find(activity => activity.goalType === "iap_purchase")

  const AddButton = styled(Button)(({})=>({
    [`&.${buttonClasses.root}`]: {
      width: "80px",
      maxHeight: "40px",
      marginLeft: "20px",
      fontSize: "12px",
      padding: "2px",
      "&.Mui-disabled": {
        backgroundColor: '#BDBDBD',
        color: "white"
      }
    },
  }))

  const iapContainerStyles = {
    display: 'flex',
    justifyContent: 'flex-start',
  }

  function submitAddIapAmount(){
    setButtonState('working')
    setButtonDisabled(true)

    addIapValue(group, value).then((res) =>{
      console.log(res)
      if(res.status === 200 && !res.data.error){
        const totalSpend = parseFloat(iapTotalSpend.replace('$', '')) * 100
        const newSpend = "$"+(parseInt(totalSpend + res.amount, 10) / 100).toFixed(2)
        group.iapTotalSpend = newSpend
        setGroup(group)
        setIapTotalSpend(newSpend)
        setButtonDisabled(false)
        setButtonState('enabled')
      } else {
        setButtonDisabled(false)
        setButtonState('enabled')
        setAlertOpen(true)
      }
    })
  }

  const handleChange = (event) => {
    const rawValue = event.target.value.replace(/[^\d]/g, ''); // Remove non-digit characters
    const formattedValue = rawValue ? (parseInt(rawValue, 10) / 100).toFixed(2) : '';

    const totalSpend = parseFloat(group.iapTotalSpend.replace('$', '')) * 100
    const iapCap = parseFloat(group.iapRewardCap.replace('$', '')) * 100
    const parsedInputValue = parseFloat(formattedValue) * 100
    const projectedTotal = totalSpend + parsedInputValue

    const allZeros = rawValue.split("").every(value => value === "0")
    const valueBlank = rawValue === "" || allZeros
    const projectedOverCap = iapCap < rawValue || projectedTotal > iapCap

    if(valueBlank || projectedOverCap){
      setButtonDisabled(true)
    } else {
      setButtonDisabled(false)
    }

    setValue(formattedValue);
  };

  function closeAlert(){
    setAlertOpen(false)
  }

  return (
    <>
      { alertOpen &&
        <IMAlerts
          severity={'error'}
          title={"Failed to Add IAP Value"}
          message={`Could not add value to the IAPs for Installed App: ${group.installedAppId}`}
          onClose={closeAlert}
        />
      }
      <div style={iapContainerStyles}>
        <TextField
          id="addIapValueField"
          label="Additional value to be reported"
          value={value}
          defaultValue="0.00"
          onChange={handleChange}
          helperText={group.iapCurrency}
          variant="outlined"
        />
        <AddButton
          variant="contained"
          disabled={buttonDisabled}
          onClick={submitAddIapAmount}
        >ADD
        </AddButton>
      </div>
    </>

  );
};

export default IapValueForm;