import React, { useEffect, useState } from 'react';
import { getPlayerPhoneObject } from '../../common/Utils'
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Tooltip from '@mui/material/Tooltip';
import { getAdminDomain } from '../../common/Utils'

import PersonIcon from '@mui/icons-material/Person';
import PhoneAndroidIcon from '@mui/icons-material/PhoneAndroid';
// import CheckBoxIcon from '@mui/icons-material/CheckBox';
import StarBorderPurple500Icon from '@mui/icons-material/StarBorderPurple500';
import { styled } from '@mui/material/styles';

import PersonOffIcon from '@mui/icons-material/PersonOff';
import ConstructionIcon from '@mui/icons-material/Construction';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import { orange, yellow } from '@mui/material/colors';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import PanToolIcon from '@mui/icons-material/PanTool';
import PhoneIcon from '@mui/icons-material/Phone';
import IMSkeletonLoader from '../../common/IMSkeletonLoader'
import CopyToClipboard from '../../common/CopyToClipboard'


const LinkSearch = styled(Typography)(({theme}) => ({
  textTransform: 'uppercase',
  textDecoration: 'underline',
  cursor: 'pointer'
}))

const GridItem = styled(Grid)(({theme}) => ({
  display: "flex",
  fontSize: '14px',
  // width: '85px',
  marginLeft: '15px',
  wordBreak: "break-word",
  alignItems: 'center'
}))
const PlayerStatusIcon = styled(ListItemIcon)(({theme}) => ({
  height: '30px',
  maxWidth: 'fit-content',
  minWidth: 'fit-content',
  alignItems: 'center',
  marginLeft: 1
}))
const PlayerStatus = styled(Typography)(({theme}) => ({
  display: "inline-block",
  fontSize: '14px',
  wordBreak: "break-word",
  minWidth: '230px',
  whiteSpace: "pre-line",
  marginLeft: '5px'
}))
const GridItemKey = styled(Grid)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '100%',
  /* identical to box height, or 12px */

  letterSpacing: '1px',

  /* Gray/600 */
  color: '#999999',
  marginBottom: 5
}))

const GridItemValue = styled(Grid)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '14px',
  lineHeight: '143%',
  /* identical to box height, or 20px */

  letterSpacing: '0.17px',
  // textDecorationLine: 'underline',

  /* Theme/Dark */

  color: '#212529'
}))
export default function StatusDetails(props) {

  const [ player, setPlayer ] = useState(props.player)
  const [ lrPlayer, setLRPlayer] = useState(null)
  const [ refresh, setRefresh ] = useState(props.refresh)
  const [ info, setInfo ] = useState({})
  const [ loading, setLoading ] = useState(true)
  const [ statusFromLR, setStatusFromLR ] = useState(null)
  const [ editableStatus, setEditableStatus ] = useState(null)


const setupEditableStatus = () => {
  let EDITABLE_STATUS = [ { field: 'developer', label: 'Developer', icon: <ConstructionIcon fontSize="small"/>, value: props.playerStatus.developer, color: 'black'},
    { field: 'disabled', label: 'Disabled', icon: <PersonOffIcon fontSize="small" color="error"/>, value: props.playerStatus.disabled, color: 'red'},

    { field: 'profanity', label: 'Profanity', icon: <MoodBadIcon fontSize="small" sx={{ color: yellow[700] }}/>, value: props.playerStatus.profanity, color: 'red'},
    { field: 'redemptionHold', label: 'Hold Redemptions', icon: <ConstructionIcon fontSize="small" sx={{ color: yellow[700] }}/>, value: props.playerStatus.redemptionHold, color: 'red'},

    { field: 'blockIp', label: 'IP Blocked', icon: <ReportProblemIcon fontSize="small" sx={{ color: orange[700] }} />, value: props.playerStatus.blockIp, color: 'red'},
    { field: 'optOutStatusValue', label: 'Do not sell my personal information', icon: <PanToolIcon fontSize="small" sx={{ color: yellow[700] }}/>, value: props.playerStatus.optOutStatusValue , color: 'red'},

  ]
  setEditableStatus(EDITABLE_STATUS)
}


const lrAdminPlayerLink = (id) =>{
    const linkStyles = {
      color: 'black'
    }
    return(
      <a target={"_blank"} style={linkStyles} href={`${getAdminDomain(props.env)}/dashboard/admin/players/${id}`}>
        {id} 
      </a>
    )
}
const setupStatus = (currentPlayerInfo) => {
  // const currentPhone = currentPlayerInfo.phoneNumbers ? currentPlayerInfo.phoneNumbers.filter((phone) => phone.current === true)[0] : null

  let STATUS_FROM_PROFILE = [
      { field: 'id', label: lrAdminPlayerLink(currentPlayerInfo.id), icon: <PersonIcon fontSize="small" color="primary"/>, value: currentPlayerInfo.id },
      { field: 'country', label: currentPlayerInfo.country, icon: <img
        loading="lazy"
        width="20"
        src={`https://flagcdn.com/w20/${currentPlayerInfo.country.toLowerCase()}.png`}
        srcSet={`https://flagcdn.com/w40/${currentPlayerInfo.country.toLowerCase()}.png 2x`}
        alt=""
      />, value: currentPlayerInfo.country},
      { field: 'device', label: currentPlayerInfo.devices && currentPlayerInfo.devices.length > 0 ? currentPlayerInfo.devices[currentPlayerInfo.devices.length - 1].model : 'Not Provided', icon: <PhoneAndroidIcon fontSize="small"/>, value: currentPlayerInfo.device, tooltip: currentPlayerInfo.devices && currentPlayerInfo.devices.length > 0 ? currentPlayerInfo.devices[currentPlayerInfo.devices.length - 1].model : null},
      { field: 'elite', label: currentPlayerInfo.elite ? 'Elite' : '', icon: <StarBorderPurple500Icon fontSize="small" color="success"/>, value: currentPlayerInfo.elite },

      ]
      STATUS_FROM_PROFILE.push(getPlayerPhoneObject(currentPlayerInfo))

    setStatusFromLR(STATUS_FROM_PROFILE)
  }

  const refreshFromAdmin = async () => {
    setLRPlayer(props.lrPlayer)
    const lastSignInAt = new Date(props.lrPlayer.lastSignInAt)
    const createdAt = new Date(props.lrPlayer.createdAt)

    let STATUS_INFO = {
      'LAST IP': { value: props.lrPlayer.ipAddress, type: 'search' },
      'LAST ACTIVE': { value: lastSignInAt.toDateString(), type: 'text' },
      'ACCT CREATED': { value: createdAt.toDateString(), type: 'text' },
      'ACCT AGE':  { value: props.lrPlayer.age ? `${props.lrPlayer.age} Days` : '0', type: 'text' },
      // 'RECRUITED BY': props.lrPlayer.recruitedBy ? props.lrPlayer.recruitedBy.name || props.lrPlayer.recruitedBy.id : '-',
      // 'RECRUITED': props.lrPlayer.recruited ? props.lrPlayer.recruited.length : '-'

    }

    if (props.lrPlayer.recruitedBy) {
      STATUS_INFO['RECRUITED BY'] = { value: props.lrPlayer.recruitedBy.name || props.lrPlayer.recruitedBy.id, type: 'text' }
    }
    if (props.lrPlayer.recruited && props.lrPlayer.recruited.length > 0) {
      STATUS_INFO['RECRUITED'] = { value: props.lrPlayer.recruited.length, type: 'text' }
    }

    setupStatus(props.lrPlayer)
    setInfo(STATUS_INFO)
    setLoading(false)
  }


  useEffect(() => {
    if (props.refresh !== refresh || editableStatus === null) {
      setRefresh(props.refresh)

     setupEditableStatus()
    }
    if (!statusFromLR) {
      setLoading(true)
      setupStatus(props.player)
    }
    if (!lrPlayer && props.lrPlayer){
      setLoading(true)
      refreshFromAdmin(props.lrPlayer)
    }

  })
  const copyToClipboard = (value) => {
    const regIP = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/gi;
    const regName = /^[a-zA-Z]+ [a-zA-Z]+$/;
    const regPhoneAndID = /^\d+$/;
    const test = regIP.test(value) || regIP.test(value)
     // || regPhoneAndID.test(value)
    // console.log('TEST', test)
    return test
  }

  const searchIP = () => {
    let newSearch = {}
    newSearch['filter'] = 'ip'
    newSearch['value'] = props.lrPlayer.ipAddress

    props.searchHeaderFilterButtonClicked(newSearch)

  }
  const searchPhone = (phoneNumber) => () => {
    if (!phoneNumber.includes("***")) {
      let newSearch = {}
      newSearch['filter'] = 'phone'
      newSearch['value'] = phoneNumber.replace(/\D/g,'').trim()

      props.searchHeaderFilterButtonClicked(newSearch)
    }


  }
  return (
    <>{loading
      ?  <IMSkeletonLoader key={`skeletonKeyItem`} itemsNumber={8} columns={4} />
      :
    <Box sx={{ flexGrow: 1 }}>
       <Grid container spacing={0} sx={{display: 'flex',
              alignItems: 'baseline'}}>
       {
         statusFromLR.map((option, i) =>
           option.label && <Grid item xs={6}>
           <Tooltip title={option.tooltip && option.tooltip !== 'Not Provided' ? option.tooltip : null}>
             <GridItem item key={option.value} value={option.value}>
                 <PlayerStatusIcon>
                   {option.icon
                      ? (option.value !== true && option.value !== false) || option.value === true
                      ? option.icon
                      : <></>
                     : <></>}
                 </PlayerStatusIcon>
                { option.value === true || option.value === false
                  ? option.value === true && <PlayerStatus>{option.label}</PlayerStatus>
                  : <>
                  {option.type === 'search' && option.label !== 'Not Provided'
                  ? <PlayerStatus ><LinkSearch onClick={searchPhone(option.label)} sx={{fontSize: '14px'}}>{option.label}</LinkSearch></PlayerStatus>
                  : <Box sx={{display: 'inline-block',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        width: '230px',
                        fontSize: '14px',
                        marginLeft: '5px',
                        whiteSpace: 'nowrap'}}>{option.label}</Box>}

                  </>}


             </GridItem>
            </Tooltip>
           </Grid>

         )}
         </Grid>
         <Grid container spacing={0} sx={{display: 'flex',
                alignItems: 'baseline', marginTop: '5px'}}>
           {editableStatus.map((option, i) =>
             <Grid item xs={12}>
              {((option.value !== true && option.value !== false) || option.value === true) &&
                <GridItem item key={option.value} value={option.value}>
                   <PlayerStatusIcon >
                     {option.icon}
                   </PlayerStatusIcon>
                  { option.value === true || option.value === false
                    ? option.value === true && <PlayerStatus sx={{fontSize: '14px', color: option.color}}>{option.label}</PlayerStatus>
                    : <PlayerStatus sx={{fontSize: '14px', color: option.color}}>{option.label}</PlayerStatus>}
               </GridItem>}
             </Grid>
           )}
          </Grid>
          <Box sx={{marginLeft: '23px', marginTop: 2}}>
          <Grid container spacing={0.5} sx={{display: 'flex',
            alignItems: 'baseline'}}>
          {
            Object.keys(info).map((option) =>
              <>
                <GridItemKey item xs={6} key={`${option}KeyLabel`} value={`${option}Value`} sx={{fontSize: '12px', color: '#888888'}}>
                  {option}
                </GridItemKey>


                <GridItemValue  item xs={6}
                    key={`${info[option].value}KeyValue`}
                    value={`${info[option].value}ValMenu`}

                  >
                  {info[option].type === 'search'
                  ? <LinkSearch onClick={searchIP} sx={{fontSize: '14px'}}>{info[option].value}</LinkSearch>
                  : info[option].value}


                </GridItemValue>
              </>
            )
            }
          </Grid>
          </Box>
    </Box>
  }</>
  )

}
