import React, {useContext, useState} from 'react';
import {
  TableCell,
  TableRow,
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import Tooltip from "@mui/material/Tooltip";
import ActivityModalButton from "./ActivityModalButton";
import BaseSupportModal from "./SupportModal/BaseSupportModal";
import {SupportModalContext} from "./SupportModal/SupportModalContext";
import FormattedDatesDisplay from "./Shared/FormattedDatesDisplay"

// Icons
import CreditCardIcon from '@mui/icons-material/CreditCard';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import PreviewIcon from '@mui/icons-material/Preview';
import WarningIcon from '@mui/icons-material/Warning';
import StarIcon from '@mui/icons-material/Star';

const ChallengeActivityRow = (props) => {
  const activity = props.activity
  const {modalOpen, setModalOpen} = useContext(SupportModalContext)
  const group = props.group

  // console.log("activity", activity)

  const imageContainerStyles = {
    flexDirection: 'column',
    display: 'flex',
    background: `url(${activity.appIcon}) no-repeat`,
    backgroundSize: '100%',
    minWidth: 40,
    minHeight: 40,
    maxHeight: 40,
    boxShadow: "0px 4px 4px 0px #00000040",
    borderRadius: "5px",
    alignSelf: "center",
  }

  const preInviteCompleteStates = [
    'Inv. Locked',
    'Inv. Expired',
    'Unlocking',
    'Dismissed',
    'Queued'
  ]

  function displayRotationDate(){
    return !preInviteCompleteStates.includes(group.status) || activity.goalType === "install_challenge"
  }

  const wrapperStyles={
    display: "flex"
  }

  const ActivityCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.body}`]: {
      minHeight: '105px',
      maxWidth: '150px'
    },
  }))

  function formatDate(epoch){
    console.log(epoch)
    if(isNaN(epoch)){
      return "-"
    }
    try {
      let date = new Date(new Date(epoch*1000).toGMTString())
      return date.toLocaleString("en-US", { hour12: true })
    } catch(err) {
      console.error(err)
      return new Date(epoch*1000).toGMTString()
    }
  }

  function chipBackgroundColor(){
    let color = '#B0BEC5'
    if(activity.status === "Started"){color = '#FFCC00'}
    if(activity.status === "Completed"){ color = '#406AB4'}
    if(activity.status === "Pending"){color = '#C62828'}
    if(activity.status === "Uninstalled"){color = '#C62828'}
    return color
  }

  const statusChipStyles = {
    backgroundColor: chipBackgroundColor(),
    margin: "0",
    justifyContent: 'center',
    minHeight: 25,
    display: 'flex',
    borderRadius: 25,
    alignItems: 'center',
    paddingLeft: '5px',
    maxHeight: '35px',
    color: 'white',
  }

  const statusChipCellStyles = {
    padding: '0'
  }

  function toggleModalVisibility(){
    console.log("called the toggle", modalOpen)
    if(modalOpen){
      setModalOpen(false)
    } else {
      setModalOpen(true)
    }
  }

  function handleModalClose(){
    console.log('sent close')
    toggleModalVisibility()
  }

  const statusChip = () => {
    return(
      <TableCell style={statusChipCellStyles}  align="center">
        <p style={statusChipStyles}>
          {activity.status}
        </p>
      </TableCell>
    )
  }

  function competedOnIcon() {
    if (activity.completedBy){
     return(
       <Tooltip title={activity.completedBy}>
        <span role="img" aria-label="person" style={{fontSize: 15, textAlign: "center", marginLeft: "5px"}}>🧑</span>
      </Tooltip>
     )
    } else {
      return <span role="img" aria-label="robot" style={{fontSize: 15, textAlign: "center", marginLeft: "5px"}}>🤖</span>
    }
  }

  function statusChipCell(){
    if(activity.statusTooltip){
      let tooltip = activity.statusTooltip
      if(!isNaN(activity.statusTooltip)){
        tooltip = formatDate(tooltip)
      }
      return(
        <Tooltip title={tooltip}>
          {statusChip()}
        </Tooltip>
      )
    } else {
      return(
        <>
          {statusChip()}
        </>
      )
    }
  }

  function completeByDateEpoch(){
    if(activity.goalType === "install_challenge"){
      // Meant to emulate the constraint outlined in CM and FA for their actual install timeframe.
      const secondsToAdd = activity.sessionInvitedDays * 24 * 60 * 60
      const baseEpoch = activity.groupStartDate + secondsToAdd
      const date = new Date(baseEpoch * 1000)
      const nextMidnight = new Date(date.toLocaleString('en-US', { timeZone: activity.timezone, hour12: false }));
      nextMidnight.setHours(24, 0, 0, 0)
      return Math.floor(nextMidnight.getTime() / 1000)
    } else {
      return activity.dateToNextState
    }
  }

  const redemptionStatusStyles = {
    // height: '30px',
    // top: '10px',
    position: 'relative',
    marginLeft: '8px',
    color: "#706f66",
  }

  function redemptionStatusTooltip(){
    let icon = <CreditCardIcon/>
    let info = activity.awardInfo

    switch (activity.awardStatus){
      case "Card Granted":
        icon = <CreditCardIcon style={redemptionStatusStyles}/>
        break
      case "Processing":
        icon = <AccessTimeIcon style={redemptionStatusStyles}/>
        break
      case "In Review":
        icon = <PreviewIcon style={{...redemptionStatusStyles, color: "#ff9500"}}/>
        break
      case "Other":
        icon = <WarningIcon style={{...redemptionStatusStyles, color: "#d3302f"}}/>
        info = `${activity.awardStatus} - ${activity.awardInfo}`
        break
      case "Points Granted":
        icon = <StarIcon style={redemptionStatusStyles}/>
        info = <span>{activity.awardStatus} <br/> {parseFloat(activity.awardInfo)}</span>
        break
    }

    return(
      <Tooltip title={info} placement="top">
        {icon}
      </Tooltip>
    )
  }



  return(
    <>
      <TableRow>

        <ActivityCell style={{padding: "6px 10px"}} component="th" scope="row" align="center">
          <div style={wrapperStyles}>
            <div style={imageContainerStyles}/>
            <div style={{marginLeft: "10px", textAlign: "left"}}>
              <p style={{margin: 0, padding: 0}}>
                {activity.displayTitle}
              </p>

              { displayRotationDate() &&
                <small>
                  <FormattedDatesDisplay
                    rootLabel={"by "}
                    epoch={completeByDateEpoch()}
                    timezone={activity.timezone}
                  />
                </small>
              }

            </div>
          </div>
        </ActivityCell>

        <TableCell align="center">
          {activity.typeLabel}
        </TableCell>

        <TableCell align="center">
          <p
            style={{
              height: '30px',
              margin: 0,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
          {activity.prizeLabel}
          {activity.awardStatus &&
            redemptionStatusTooltip()
          }
          </p>
        </TableCell>

        {statusChipCell()}

        <TableCell align="center">
          { activity.completedOn &&
            <p style={{margin: 0, padding: 0}}>
                <FormattedDatesDisplay
                  epoch={activity.completedOn}
                  timezone={activity.timezone}
                  child={competedOnIcon()}
                />
            </p>
          }
          { activity.buttonState !== "hidden" &&
            <ActivityModalButton
              buttonState={activity.buttonState}
              goalType={activity.goalType}
              toggleModalVisibility={toggleModalVisibility}
              handleModalClose={handleModalClose}
            />
          }

        </TableCell>
      </TableRow>
    </>
  )
}

export default ChallengeActivityRow