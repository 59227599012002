import React, { useContext, useState, useEffect } from "react";
import SpotlightStatusFilter from "./SpotlightStatusFilter"
import SpotlightTextFilter from "./SpotlightTextFilter"
import { SpotlightContext } from "./SpotlightContext";
import { spotlightStates } from "../../../../../utils/constants";

const SpotlightFilters = () => {
  const {shownSpotlights, originSpotlights, setShownSpotlights, showNextSpotlights, setShowNextSpotlights } = useContext(SpotlightContext);
  const [selectedStatus, setSelectedStatus] = useState('all')
  const [textFilteredSpotlights, setTextFilteredSpotlights] = useState([])
  // const [showNextSpotlights, setShowNextSpotlights] = useState(false)

  const labelMap = {
    available: 'Offer Available',
    progress: 'In Progress',
    negative: 'Expired',
    completed: 'Completed',
    next: 'Skipped'
  }

  function selectStateLabel(state){
    let label = "Unhandled";

    if(spotlightStates.availableStates.includes(state)){ label = labelMap.available }
    if(spotlightStates.progressStates.includes(state)){ label = labelMap.progress }
    if(spotlightStates.negativeEndStates.includes(state)){ label = labelMap.negative }
    if(spotlightStates.positiveEndStates.includes(state)){ label = labelMap.completed }
    if(spotlightStates.skippedStates.includes(state)){ label = labelMap.next }
    return label
  }

  useEffect(()=>{
    let filteredSpotlights = filterSpotlights()
    setShownSpotlights(filteredSpotlights)
  },[selectedStatus, textFilteredSpotlights, showNextSpotlights, originSpotlights])

  useEffect(()=>{
    setTextFilteredSpotlights(shownSpotlights)
  },[])

  function filterSpotlights(){
    const stateFiltered = []

    for (let i = 0; i < textFilteredSpotlights.length; i++) {
      let spotlight = textFilteredSpotlights[i]
      if (spotlight.state === "next" && !showNextSpotlights){ continue }
      if(selectStateLabel(spotlight.state) === selectedStatus || selectedStatus === 'all'){
        stateFiltered.push(textFilteredSpotlights[i])
      }
    }
    return stateFiltered
  }

  const filterStyles = {
    display: 'flex'
  }

  return(
    <div style={filterStyles}>
      <SpotlightTextFilter
        setTextFilteredSpotlights={setTextFilteredSpotlights}
      />
      <SpotlightStatusFilter
        selectedStatus={selectedStatus}
        setSelectedStatus={setSelectedStatus}
        showNextSpotlights={showNextSpotlights}
        setShowNextSpotlights={setShowNextSpotlights}
      />
    </div>
  )
}

export default SpotlightFilters