import React, { useEffect, useState } from 'react';
import { styled } from '@mui/material/styles';
import dayjs from 'dayjs';
import { useNavigate } from "react-router-dom";

import SideMenu from './SideMenu'
import ConfirmDialog from '../../common/ConfirmDialog'
import IMAlerts from '../../common/IMAlerts'
import Points from './Points'
import BusinessMetrics from './BusinessMetrics'
import ProgramsContainer from './ProgramsContainer'
import StatusDetails from './StatusDetails'
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Tooltip, { tooltipClasses } from '@mui/material/Tooltip';

import { editPlayerProfile, playerDelete } from '../../../models/Player/Profile'
import ConfirmPlayerDialogsContents from '../Common/ConfirmPlayerDialogsContents'
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Chip from '@mui/material/Chip';
import Divider from '@mui/material/Divider';

import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import GroupIcon from '@mui/icons-material/Group';
import CloseIcon from '@mui/icons-material/Close';


const GridItemTitle = styled(Grid)(({ theme }) => ({
  textTransform: 'uppercase',
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '100%',
  /* identical to box height, or 12px */

  letterSpacing: '1px',

  /* Gray/600 */
  // color: '#999999',
}))

const GridItemValue = styled(Grid)(({ theme }) => ({
  fontWeight: 400,
  fontSize: '12px',
  lineHeight: '100%',
  /* identical to box height, or 12px */

  letterSpacing: '1px',

  /* Gray/600 */
  // color: '#999999',
}))

const GridItem = styled(Grid)(({ theme }) => ({
  fontWeight: 600,
  // fontSize: '34px',
  fontSize:'12px',
  lineHeight: '100%',
  color: '#999999',
  /* or 42px */

  letterSpacing: '0.25px',

  /* Light/Other/Rating Active */
}))

const PlayerTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    // backgroundColor: '#f5f5f9',
    // color: 'rgba(0, 0, 0, 0.87)',
    maxWidth: 300,
    fontSize: theme.typography.pxToRem(12),
    border: '1px solid #dadde9',
  },
}));

const utc = require('dayjs/plugin/utc')
const timezone = require('dayjs/plugin/timezone')
const advanced = require("dayjs/plugin/advancedFormat")

dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(advanced)

export default function SideContainer(props) {

  const [ refresh, setRefresh ] = useState(false)
  const [ contentConfirm, setContentConfirm ] = useState(null)
  const [ alert, setAlert ] = useState(null)
  const [ player, setPlayer ] = useState(props.player)
  const [ statusChangedAddedInfo, setStatusChangedAddedInfo ] = useState(null)
  const [ disableEditing, setDisableEditing] = useState(props.disableEditing)

  const [ playerStatus, setPlayerStatus ] = useState([
    { name: 'developer', check: props.player.developer, label: 'Developer', color: 'success' },
    { name: 'profanity', check: props.player.profanity, label: 'Profanity', color: 'error' },
    { name: 'disabled', check: props.player.disabled, label: 'Disabled', color: 'error' },
    { name: 'blockIp', check: props.player.blockIp, label: 'Block IP', color: 'error' },
    { name: 'holdRedemptions', check: props.player.redemptionHold, label: 'Hold Redemptions', color: 'error' },
    { name: 'optOutStatusValue', check: props.player.optOutStatusValue, label: 'Do Not Sell My Personal Information', color: 'error' },
  ]);
  const [ playerStatusEditable, setPlayerStatusEditable ] = useState({
    developer: props.player.developer,
    profanity: props.player.profanity,
    disabled: props.player.disabled,
    blockIp: props.player.blockIp,
    redemptionHold: props.player.redemptionHold,
    optOutStatusValue: props.player.optOutStatusValue ,
  })
  const [ confirmStatus, setConfirmStatus ] = useState(null)
  const [ dialogProps, setDialogProps ] = useState(null)
  const [ lrPlayer, setLRPlayer ] = useState(null)
  const [ lrPlayerActions, setLRPlayerActions ] = useState([])
  const [ enableDialog, setEnableDialog ] = useState(true)
  const navigate = useNavigate();

  const statusEditableFromPlayer = (currentPlayer) => {
    setPlayerStatusEditable({
      developer: currentPlayer.developer,
      profanity: currentPlayer.profanity,
      disabled: currentPlayer.disabled,
      blockIp: currentPlayer.blockIp,
      redemptionHold: currentPlayer.redemptionHold,
      optOutStatusValue: currentPlayer.optOutStatusValue,
    })
    setPlayerStatus([
      { name: 'developer', check: currentPlayer.developer, label: 'Developer', color: 'success' },
      { name: 'profanity', check: currentPlayer.profanity, label: 'Profanity', color: 'error' },
      { name: 'disabled', check: currentPlayer.disabled,  label: 'Disabled', color: 'error' },
      { name: 'blockIp', check: currentPlayer.blockIp, label: 'Block IP', color: 'error' },
      { name: 'redemptionHold', check: currentPlayer.redemptionHold, label: 'Hold Redemptions', color: 'error' },
      { name: 'optOutStatusValue', check: currentPlayer.optOutStatusValue, label: 'Do Not Sell My Personal Information', color: 'error' },
    ])
    setRefresh(!refresh)
  }
  const getLRPlayerActions = (lrPlayer) => {

    let actions = []

    actions.push({
      alertMessage: `Account has been sucessfully deleted`,
      action: 'delete',
      content: <ConfirmPlayerDialogsContents
                      dialog={'delete'}
                      playerId={lrPlayer.id}
                      toggleConfirmEnable={setEnableDialog}
                      />,
      title: `Delete Account`,
      confirmBtnText: `Delete Account`,
      textDecoration: 'underline',
      confirmBtnColor: 'error',

      icon: <CloseIcon fontSize="small" color="error"/> })

    return actions;
  }

  useEffect(() => {
    if (!lrPlayer && props.lrPlayer && props.lrPlayer.id)  {
      setLRPlayer(props.lrPlayer);
      setPlayer(props.lrPlayer);
      statusEditableFromPlayer(props.lrPlayer)
      setLRPlayerActions(getLRPlayerActions(props.lrPlayer))
    }
  })

  const Item = styled(Box)(({ theme }) => ({

    padding: theme.spacing(1),
    backgroundColor: 'transparent !important',
    textAlign: 'center',
    // height: '67px',
    fontWeight: 500,
    fontSize: '20px',
    lineHeight: '120%',
    display: 'flex',
    alignItems: 'center',
    color: '#212529',
    flex: 'none',
    order: 0,
    background: '#ECEFF1',
    flexGrow: 0
  }));

  const initializeDialog = () => {
    setConfirmStatus(null)
    setContentConfirm(null)
    setDialogProps(null)
    setStatusChangedAddedInfo(null)
  }
  const viewAlert = () => {
    if (alert) {
      alert.view = true
      setAlert(alert)
      setRefresh(!refresh)
    }
  }

  const editPlayer = async ({ data, addedInfo }) => {
    setDisableEditing(true)
    const response  = await editPlayerProfile({ playerId: props.player.id, data, addedInfo })
    if (response.status === 200 && response.player) {
      statusEditableFromPlayer(response.player)
      setPlayer(response.player)
      props.resetNotes(response.player.notes)

      viewAlert()
    } else {
      const message = response.error || 'Failed to update player'
      setAlert({ view: true, severity: 'error', message })
    }
    initializeDialog()
    setDisableEditing(false)
  }

  const onConfirmAction = async () => {
    setDisableEditing(true)
    console.log('CONFIRM DIALOG ???? ', dialogProps)
    if (dialogProps.action === 'delete') {
      const response = await playerDelete({ playerId: lrPlayer.id })
      const message = response.status === 200 ? 'Player is in Graveyards' : 'Dailed to Delete'
      setAlert({ view: true, severity: response.status === 200 ? 'success' : 'error', message })
      console.log('DELETE RESPONSE  ', response)

      if (response.status === 200) {
        navigate(`/player/${lrPlayer.id}`)
      }


    }

    setDisableEditing(false)

    initializeDialog()
  }



  const onConfirmStatus = async () => {

    let updatedStatus = []

    let updateEditableStatus = {}

    let updateAttr = {}

    playerStatus.forEach((statusObj) => {
        if (statusObj.name === confirmStatus.status) {
            statusObj.check = confirmStatus.check
            updateEditableStatus[confirmStatus.status] = confirmStatus.check
            updateAttr[confirmStatus.status] = confirmStatus.check
        } else {
          updateEditableStatus[statusObj.name] = playerStatusEditable[statusObj.name]
        }

      updatedStatus.push(statusObj)
    })
    await editPlayer({ data: updateAttr, addedInfo: statusChangedAddedInfo })

  }

  const STATUS_DIALOG_ON = (check = false) => {
     return {
      'developer': { alertMessage: `${check ? 'Player successfully set as developer.' : 'Player is no longer Developer'}`, content: !check ? null : 'This will give the account super powers and should be only used for internal accounts. Do you want to continue?'},
      'blockIp': { alertMessage: `${check ? 'IP successfully blocked' : 'IP successfully UN blocked'}`, content: !check ? null : 'Blocking this IP will deactivate all players using the same IP and prevent new players of being created throught it.'},
      'redemptionHold': { alertMessage: `${check ? 'Redemption is on HOLD' : 'Redemption is NO longher on hold'}`, content: !check ? null : 'This will put all future player’s redemptions in hold to be manually reviewed. Do you want to continue?'},
      'disabled': { alertMessage: `${check ? 'Account has been successfuly disabled' : 'Account has been successfuly enabled'}`, content:
      <ConfirmPlayerDialogsContents
        dialog={'disable'}
        check={check}
        disabledReasons={lrPlayer.disabledReasons}
        reason={!statusChangedAddedInfo || !statusChangedAddedInfo.disabledReason ?  lrPlayer.disabledReasons[0] : statusChangedAddedInfo.disabledReason}
        onDisableReason={setStatusChangedAddedInfo}
        />
        , title: `${!check ? 'Enable' : 'Disable'} Account`, confirmBtnText: `${!check ? 'Enable' : 'Disable'}`, confirmBtnColor: 'error' },
      'profanity': { alertMessage: `${check ? 'Profanity flag added to account' : 'Profanity flag removed to account'}`, content: '', title: ''},
      'optOutStatusValue': { alertMessage: `${check ? 'Player successfuly OPT OUT' : 'Player is NO longer OPT OUT'}`, content: '', title: ''
                            , confirmBtnText: `Turn Do Not Sell ${ check ? 'ON' : 'OFF'}`, confirmBtnColor: check ? 'error' : 'success',
                            content:
                            <ConfirmPlayerDialogsContents
                              dialog={"optOut"}
                              toggleConfirmEnable={setEnableDialog}
                              setTicketNumber={setStatusChangedAddedInfo}
                              check={check}
                            />},

    }
  }

  const onStatusChange = ({ status, check }) => {

    const statusProps = STATUS_DIALOG_ON(check)[status]
    let title = status.replace(/([a-z])([A-Z])/g, '$1 $2');
    title = title.charAt(0).toUpperCase() + title.slice(1);

    title = statusProps && statusProps.title ? statusProps.title : `${title}`
    const confirmBtnText = statusProps && statusProps.confirmBtnText ? statusProps.confirmBtnText : null
    const confirmBtnColor = statusProps && statusProps.confirmBtnColor ? statusProps.confirmBtnColor : null
    const cancelBtnText = statusProps && statusProps.canceleBtnText ? statusProps.canceleBtnText : null
    const cancelBtnColor = statusProps && statusProps.cancelBtnColor ? statusProps.cancelBtnColor : null

    const content = check
                      ? statusProps && statusProps.content
                          ? statusProps.content
                          : `Turning ${title} Mode ON.`
                      : statusProps && statusProps.content ? statusProps.content : `Turning ${title} Mode OFF.`

    setContentConfirm(statusProps && statusProps.content ? '' : 'Are you sure ?')
    setConfirmStatus({ status, check, statusChangedAddedInfo })
    setDialogProps({ content, title, contentConfirm, cancelBtnText, cancelBtnColor, confirmBtnText, confirmBtnColor, type: 'status'  })
    setAlert({ severity: 'success', message: statusProps.alertMessage, view: false })
    setEnableDialog(status !== 'optOutStatusValue')
    setRefresh(!refresh)

  }

  const onActionMade = (action) => {
    setDialogProps(action)
    setEnableDialog(action.type !== 'delete')
    setAlert({ severity: 'success', message: action.alertMessage, view: false })

    setRefresh(!refresh)
  }

  const onDialogCancel = ()  => {
    initializeDialog()
  }

  const onCloseAlert = () => {
    setAlert(null)
  }

  const getAge = (birthday) => {
    const todayStart = dayjs.utc().startOf('day');
    return todayStart.diff(birthday, 'year')

  }

  const PLAYER_TIPS =
  { 'Gender': player.gender || 'Unknown',
    'Birthday': player.birthday || 'Unknown',
    'Age': player.birthday ? getAge(dayjs((player.birthday))) : 'Unknown' ,
    'Time Zone': player.timeZone || 'Unknown',
    'IP Timezone': player.ipTimeZone || 'Unknown' }

  return (
    <Box
      sx={{ position: 'absolute',
            width: '300px',
            minHeight: '100%',
            overflowY: 'scroll',
            left: '0px',
            top: '64px',
            background: '#ECEFF1',
            '&::-webkit-scrollbar': {
                display: 'none',
                width: 0,
                height: 0,
                '-ms-overflow-style': 'none',  /* IE and Edge */
                'scrollbar-width': 'none'
            },
       '.MuiTabs-indicator': { display: 'none'},
     '.MuiButtonBase-root-MuiTabScrollButton-root.Mui-disabled': { display: 'none'} }}
    >
    {alert && alert.view &&
      <Box sx={{ width: '70%' }}>
        <IMAlerts
          message={alert.message}
          onClose={onCloseAlert}
          success={!alert.error}
          severity={alert.severity}
          error={alert.error}/>
        </Box>}
      {dialogProps && <ConfirmDialog
        onCancel={onDialogCancel}
        {...dialogProps}
        contentConfirm={contentConfirm}
        onConfirm={dialogProps.type ? onConfirmStatus : onConfirmAction}
        enableConfirm={enableDialog}
        />}
    <Box sx={{
        backgroundColor: '#dadfe2',
        height: 'max-content',
        position: 'fixed',
        zIndex: 9,
        // marginBottom: '20px',
        width: '300px',
         }}>
         <Box sx={{width: '300px',
                    height: '7px',
                    left: '0px',
                    top: '64px',
                    background: player.disabled ? '#E35D6A' : '#2e7d32'}}/>

         <Stack
           sx={{width: '-webkit-fill-available'}}
           alignItems="flex-start"
           spacing={0.5}>

           <Stack direction="row"
             sx={{ marginLeft: '10px',
                  width: '95%',
                  justifyContent: 'space-between'}}
             justifyContent="center"
             alignItems="flex-start"
             spacing={0.5}>
             <PlayerTooltip
                 title={
                   <React.Fragment>
                     <Typography color="inherit">{props.player.name && props.player.name.trim() !== '' ? props.player.name : props.player.id}</Typography>

                       <Grid container spacing={0.5} sx={{marginTop: '1px'}}>{
                       Object.keys(PLAYER_TIPS).map((attr) =>
                         <><Grid item xs={6}>
                           <GridItemTitle >{attr}</GridItemTitle>

                         </Grid>
                         <Grid item xs={6}>
                           <GridItemValue>{PLAYER_TIPS[attr]}</GridItemValue>

                         </Grid>
                       </>
                      )}
                      </Grid>
                   </React.Fragment>
                 }
               >
             <Item sx={{
               justifyContent: 'center',
               // minWidth: '300px',
               fontWeight: 600,
               padding: '16px',
              backgroundColor: '#dadfe2',
            }}>{props.player.name && props.player.name.trim() !== '' ? props.player.name : props.player.id}</Item></PlayerTooltip>
            <Item sx={{maxWidth: 'min-content'}}>
{           playerStatus &&
              <SideMenu
                disableEditing={disableEditing || !lrPlayer}
                onStatusChange={onStatusChange}
                playerStatus={playerStatus}
                playerActions={lrPlayerActions}
                refresh={refresh}
                permittedActions={props.permittedActions}
                onActionMade={onActionMade}/>
}            </Item>

           </Stack>
           {
             player.disabled
             ? <Grid container sx={{marginLeft: '23px !important'}}>
              <Grid container spacing={0.5} >
             <GridItemTitle item xs={4}>
               Reason
             </GridItemTitle>
             <GridItemTitle item xs={8}>
               Disabled At
             </GridItemTitle>
           </Grid>
             <Grid container spacing={0.5} sx={{ marginBottom: 2}}>
               <GridItem item xs={4}>
                 {player.disabledReason ? player.disabledReason.replaceAll('_', ' ').toUpperCase() : '-'}
               </GridItem>
               <GridItem item xs={8} >
                  {
                    `${(new Date(player.disabledAt)).toLocaleString("sv-SE", {
                      timeZone: "America/Los_Angeles"
                    })} PDT`
                  }
               </GridItem>
             </Grid>
           </Grid>
           : <></>
           }
        </Stack>
    </Box>
    <Box sx={{  marginTop: player.disabled ? '120px' : '75px' }}>
  { player && playerStatusEditable &&
    <StatusDetails
      env={props.env}
      player={props.player}
      playerStatus={playerStatusEditable}
      searchHeaderFilterButtonClicked={props.searchHeaderFilterButtonClicked}
      refresh={refresh}
      lrPlayer={lrPlayer}
      />}
    <Divider sx={{ margin: '20px' }}/>
    <Points player={props.player} />
    <Divider sx={{ margin: '20px' }}/>
    <ProgramsContainer player={player} env={props.env}/>
    <Divider sx={{ margin: '20px' }}/>
    <BusinessMetrics player={player} lrPlayer={lrPlayer} />
    <Divider sx={{ margin: '20px' }}/>
    </Box>
  </Box>
  )
}
// {player.disabledAt.toLocaleString("en-US", {
//      timeZone: "America/Los_Angeles",
//    })}
