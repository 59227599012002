import React, { useContext, useState, useEffect } from "react";

import TableCell from '@mui/material/TableCell';
import {SpotlightContext} from "./SpotlightContext";
import { spotlightStates } from "../../../../../utils/constants";
import Tooltip from "@mui/material/Tooltip";

const SpotlightOfferStatusCell = (props) => {
  const spotlight = props.spotlight

  const ltGreen = '#82B11D'
  const dkBlue = '#406AB4'
  const errorRed = '#D32F2F'
  const neutralGrey = '#B0BEC5'

  const offerStatusCellStyles = {
    backgroundColor: 'rgb(214 214 214)',
    // minWidth: 150,
    justifyContent: 'flex-start',
    minHeight: 33,
    display: 'flex',
    borderRadius: 25,
    alignItems: 'center',
    paddingLeft: '5px',
    maxHeight: '35px'
  }

  const circleStyle = {
    width: 25,
    height: 25,
    backgroundColor: chooseCircleColor(),
    borderRadius: 15,
    position: 'relative'
  }

  function processStateLabel(givenString){
    let string = givenString;
    if(spotlightStates.positiveEndStates.includes(string)){ string = 'Completed' }
    if(spotlightStates.negativeEndStates.includes(string)){ string = 'Expired' }
    if(spotlightStates.progressStates.includes(string)){ string = 'In Progress' }
    if(spotlightStates.availableStates.includes(string)){ string = 'Offer Available' }
    if(spotlightStates.skippedStates.includes(string)){ string = 'Skipped' }
    return string
  }

  function chooseCircleColor(){
    let color = neutralGrey
    if (spotlightStates.progressStates.includes(spotlight.state)){color = ltGreen}
    if (spotlightStates.negativeEndStates.includes(spotlight.state)){color = errorRed}
    if (spotlightStates.positiveEndStates.includes(spotlight.state)){color = dkBlue}
    if (spotlightStates.skippedStates.includes(spotlight.state)){color = dkBlue}
    return color
  }

  return(
    <TableCell>
      <Tooltip title={spotlight.state}>
        <div style={{display:'flex', justifyContent: 'center'}}>
          <div style={offerStatusCellStyles}>
            <div style={circleStyle}></div>
            <p style={{margin: "auto", marginLeft:'8px', paddingRight: '10px'}}>{processStateLabel(spotlight.state)}</p>
          </div>
        </div>
      </Tooltip>

    </TableCell>
  )
}

export default SpotlightOfferStatusCell;