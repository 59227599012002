import React, { useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import NumberInput from '../common/NumberInput'
import CreditCardIcon from '@mui/icons-material/CreditCard';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import Checkbox from '@mui/material/Checkbox';
import ErrorIcon from '@mui/icons-material/Error';
import WarningIcon from '@mui/icons-material/Warning';

import { COUNTRY_MAP } from './utils'
import { getSupportdCountires } from '../common/Utils'

const SPENDING_COST_THRESHOLD = 7500
function AttentionOverCost({count, amount, currency}) {
  return <Box sx={{ background: "#ffebee",
          // height: '50px',
          display: 'flex',
          alignItems: 'center',
          margin: '8px'}}>
          <Stack spacing={2} >
          <Typography sx={{ fontWeight: 700}}>

            <Box sx={{height: '20px', display: 'flex', margin: 1}}><ErrorIcon color='error' sx={{marginLeft: 1, marginRight: 1}}/>Attention</Box>
           </Typography>

          <Typography component="span" sx={{display: 'flex'}}>

             <Typography sx={{ marginLeft: 2}}>
              The initial cost to fund this amount of cards is

              </Typography>
              <Typography sx={{ marginLeft: 1, fontWeight: 700}}>

                 {count * amount} {currency}
               </Typography>

          </Typography>
          <Typography sx={{ marginLeft: 2, fontWeight: 700}}>

             <Box sx={{height: '20px', display: 'flex', marginLeft: 2, marginBottom: 1}}>Please make sure this is the desired amount prior to place</Box>
           </Typography>
           </Stack>
  </Box>
}
export function EditingStockLevel(props) {

  const [ enableCardEditingConfirm, setEnableCardEditingConfirm ] = useState(props.enableCardEditingConfirm)
  const [ currentCard, setCurrentCard ] = useState(props.currentCard)
  const [ card, setCard ] = useState(props.card)
  const onCheckToggle = () => {
    props.onCheckToggle(!enableCardEditingConfirm)

    setEnableCardEditingConfirm(!enableCardEditingConfirm)
  }
  return (
    <Stack spacing={4} >
      <Typography sx={{weight: 700, fontSize: '16px', lineHeight: '24px'}}>
       {`You’re making changes to the ${props.devSettings ? 'DEV': ''} In-Stock quantities for the following gift card.`}
      </Typography>
      <Box sx={{ background: "#e3f2fd",
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                margin: '8px'}}>
        <Typography sx={{fontSize: '14px', fontWeight: 400, margin: 2, display: 'flex'
          }} >
            <Box sx={{height: '20px', display: 'flex', marginRight: 2}}><img src={COUNTRY_MAP[card.country].icon}/></Box>{` ${card.country} - ${card.name} - ${card.amount} $`}
         </Typography>
      </Box>
      {
        props.challengeBoost &&  currentCard.boost !== card.boost && card.boost === false &&
        <Box sx={{ background: "#FFF9C4",
               display: 'flex',
               alignItems: 'baseline',
               margin: '10px'}}>
               <Stack spacing={2} direction="row" sx={{margin: '12px'}}>
                   <WarningIcon color='warning' sx={{marginRight: 1, marginTop: 2, marginLeft: 2}}/>

                  <Stack spacing={1} >

                    <Typography sx={{ }}>

                       {"This card cannot be disabled because it is associated with "}
                    </Typography>

                   <Stack spacing={1}>

                      <Stack spacing={1} direction="row">
                        <Typography sx={{display: 'inline', color: '#01579B' }} >
                         {`the ${props.challengeBoost.challengeAssociatedName}  `}
                        </Typography>
                        <Typography sx={{ margin: 1}}>

                         {` in PlayMax. `}
                        </Typography>
                      </Stack>

                     <Typography sx={{ margin: 2}}>

                      {"Please update the challenge to use a different prize before disabling the card."}
                     </Typography>
                   </Stack>

                 </Stack>
               </Stack>

        </Box>

      }
      <Box sx={{ background: "#FEEBEE",
                display: 'flex',
                alignItems: 'center',
                margin: '8px'}}>

          <Stack spacing={1} sx={{fontSize: '14px', margin: '15px'}}>

            <Typography component="span" sx={{fontWeight: 700, display: 'flex'}}>
              Boost:
             {currentCard.boost !== card.boost && <><Typography sx={{ textDecoration: 'line-through',marginLeft: 2}}>
                 {currentCard.boost ? '  Enabled' : '  Disabled'}
               </Typography>
               <Typography sx={{ marginLeft: 2}}>
                  >
                </Typography>
               </>
               }
             <Typography sx={{marginLeft: 2}}>{card.boost ? '  Enabled' : '  Disabled'}</Typography>
            </Typography>
            <Typography component="span" sx={{fontWeight: 700, display: 'flex'}}>
              Standard:
             {currentCard.standard !== card.standard && <><Typography sx={{ textDecoration: 'line-through',marginLeft: 2}}>
                 {currentCard.standard ? '  Enabled' : '  Disabled'}
               </Typography>
               <Typography sx={{ marginLeft: 2}}>
                  >
                </Typography>
               </>
               }
             <Typography sx={{marginLeft: 2}}>{card.standard ? '  Enabled' : '  Disabled'}</Typography>
            </Typography>
          { props.devSettings
            ? <Typography component="span" sx={{fontWeight: 700, display: 'flex'}}>
              DEV In-Stock Target:
             {currentCard.boostDevTargetStockLevel !== card.boostDevTargetStockLevel && <><Typography sx={{ textDecoration: 'line-through',marginLeft: 2}}>
                 {currentCard.boostDevTargetStockLevel}
               </Typography>
               <Typography sx={{ marginLeft: 2}}>
                  >
                </Typography>
               </>
               }
             <Typography sx={{marginLeft: 2}}>{card.boostDevTargetStockLevel}</Typography>
            </Typography>
            : <Typography component="span" sx={{fontWeight: 700, display: 'flex'}}>
              In-Stock Target:
             {currentCard.inStockTarget !== card.inStockTarget && <><Typography sx={{ textDecoration: 'line-through',marginLeft: 2}}>
                 {currentCard.inStockTarget}
               </Typography>
               <Typography sx={{ marginLeft: 2}}>
                  >
                </Typography>
               </>
               }
             <Typography sx={{marginLeft: 2}}>{card.inStockTarget}</Typography>
            </Typography>}
         </Stack>

      </Box>
      {card.inStockTarget * card.amount >= SPENDING_COST_THRESHOLD && <AttentionOverCost count={card.inStockTarget} amount={card.amount} currency={card.currency} />}

      <FormGroup>
        <FormControlLabel control={<Checkbox checked={enableCardEditingConfirm} disabled={props.disableConfirm} color="default"/>} label="Confirm Changes" onChange={onCheckToggle}/>

      </FormGroup>
    </Stack>
  )
}

export function AddNewCard(props) {

  const [ enableConfirm, setEnableConfirm ] = useState(props.enableConfirm)
  const [ currentCard, setCurrentCard ] = useState(props.currentCard)
  const [ card, setCard ] = useState(props.card)
  const [ newCard, setNewCard ] = useState(null)
  const [ boostInventory, setBoostInventory ] = useState(true)
  const [ standardInventory, setStandardInventory ] = useState(false)

  const [ countries, setCountries ] = useState(getSupportdCountires())
  const [ cardsOptions, setCardsOptions ] = useState(props.cards)
  const [ selectedCoutry, setSelectedCoutry ] = useState('')
  const [ countryCardsOptions, setCountryCardsOptions ] = useState([])
  const [ selectedCountryDetails, setSelectedCountryDetails ] = useState(null)
  const [ selectedCard, setSelectedCard ] = useState(null)
  const [ rangeValue, setRangeValue ] = useState('')
  const [ cardCountConfirm, setCardCountConfirm ] = useState(0)
  const [ cardCount, setCardCount ] = useState(0)
  const [ confirmed, setConfirmed ] = useState(false)
  const [ desiredAmount, setDesiredAmount ] = useState(0)
  const onCheckToggle = () => {
    props.onCheckToggle(!enableConfirm && confirmed)

    setEnableConfirm(!enableConfirm )
  }
  const selectDesiredCountry = (event, newValue) => {
    if (!newValue || !newValue.value) {
      setSelectedCard(null)
      setCountryCardsOptions([])
      setSelectedCountryDetails(null)

    } else {
      setSelectedCoutry(newValue.value.toLowerCase())
      setSelectedCountryDetails(newValue)
      setCountryCardsOptions(cardsOptions[newValue.value.toLowerCase()])

    }
    setRangeValue('')
    setSelectedCard(null)
    setCardCountConfirm(0)
    setCardCount(0)
    setConfirmed(false)
    setDesiredAmount(0)
    setNewCard({ card: null, amount: 0, total: 0, totalConfirmed: 0, standardInventory, boostInventory, currency: newValue.currency })
    props.onNewCardFieldsChange({ card: null, amount: 0, total: 0, confirmed: false, standardInventory, boostInventory, currency: newValue.currency })

  }
  const selectDesiredCountryCard = (event, newValue) => {
    setSelectedCard(newValue)
    setRangeValue(`${newValue.min} to ${newValue.max} ${selectedCountryDetails.currency}`)
    setCardCount(0)
    setCardCountConfirm(0)
    setConfirmed(true)
    setDesiredAmount(newValue.min)
    setNewCard({ card: newValue, amount: newValue.min, total: 0, totalConfirmed: 0, standardInventory, boostInventory, currency: selectedCountryDetails.currency })

    props.onNewCardFieldsChange({ card: newValue, amount: newValue.min, total: 0, confirmed: false, standardInventory, boostInventory, currency: selectedCountryDetails.currency })
  }
  const onChangeCardAmount = (newValue) => {
    setDesiredAmount(newValue)
    setNewCard({ card: newCard.card, amount: newValue, total: newCard.total, totalConfirmed: newCard.totalConfirmed, standardInventory, boostInventory, currency: selectedCountryDetails.currency })

    props.onNewCardFieldsChange({ card: newCard.card, amount: newValue, total: newCard.total, confirmed: newCard.totalConfirmed === newCard.total, standardInventory, boostInventory, currency: selectedCountryDetails.currency })

  }
  const onChangeCardCount = (newValue) => {
    setCardCount(newValue)
    // console.log('Confirm?2222??? ', newValue === cardCountConfirm, parseInt(newValue) === cardCountConfirm, newValue, cardCountConfirm)
    setConfirmed(parseInt(newValue) === cardCountConfirm)

    props.onCheckToggle(parseInt(newValue) === cardCountConfirm && enableConfirm)
    setNewCard({ card: newCard.card, amount: newCard.amount, total: newValue, totalConfirmed: newCard.totalConfirmed, standardInventory, boostInventory, currency: selectedCountryDetails.currency })

    props.onNewCardFieldsChange({ card: newCard.card, amount: newCard.amount, total: newValue, confirmed: newCard.totalConfirmed === newValue, standardInventory, boostInventory, currency: selectedCountryDetails.currency})

  }
  const onChangeCardCountConfirm = (newValue) => {
    setCardCountConfirm(newValue)
    // console.log('Confirm???? ', newValue === cardCount, newValue, cardCount)
    setConfirmed(parseInt(newValue) === cardCount)

    props.onCheckToggle(parseInt(newValue) === cardCount && enableConfirm)
    props.onNewCardFieldsChange({ card: newCard.card, amount: newCard.amount, total: newCard.total, confirmed: newValue ===  newCard.total, standardInventory, boostInventory, currency: selectedCountryDetails.currency })
    setNewCard({ card: newCard.card, amount: newCard.amount, total: newCard.total, totalConfirmed: newValue, standardInventory, boostInventory, currency: selectedCountryDetails.currency })

  }

  return (
    <Stack spacing={4} >
      <Typography sx={{weight: 700, fontSize: '16px', lineHeight: '24px'}}>
        Select the country you want to browse the gift cards.
      </Typography>
      <Stack spacing={2} direction='row'>
        <Autocomplete
          id="country-select"
          sx={{ width: 300 }}
          options={countries}
          autoHighlight
          onChange={selectDesiredCountry}
          getOptionLabel={(option) => option.label}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...optionProps}
              >
                <img
                  loading="lazy"
                  width="20"
                  srcSet={`${option.icon} 2x`}
                  src={option.icon}
                  alt=""
                />
                {option.label} ({option.code})
              </Box>
            );
          }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Country"
                slotProps={{
                  htmlInput: {
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  },
                }}
              />
            )}
          />
        <Autocomplete
          id="cards-select"
          sx={{ width: 300 }}
          options={countryCardsOptions}
          disabled={!countryCardsOptions || countryCardsOptions.length === 0}
          autoHighlight
          disableClearable
          value={selectedCard}
          getOptionLabel={(option) => option.name}
          onChange={selectDesiredCountryCard}
          renderOption={(props, option) => {
            const { key, ...optionProps } = props;
            return (
              <Box
                key={key}
                component="li"
                sx={{ '& > img': { mr: 2, flexShrink: 0 } }}
                {...optionProps}
              >

                {option.name}
              </Box>
            );
          }}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Gift Card desired"
                slotProps={{
                  htmlInput: {
                    ...params.inputProps,
                    autoComplete: 'new-password', // disable autocomplete and autofill
                  },
                }}
              />
            )}
          />
      </Stack>
      <Typography sx={{weight: 700, fontSize: '16px', lineHeight: '24px'}}>
        Make it available for
      </Typography>
      <Stack spacing={14} direction='row'>

        <FormGroup>
          <FormControlLabel control={<Checkbox checked={standardInventory} disabled color="default"/>}
          label={'Standard Redemptions'}
           />

        </FormGroup>
        <FormGroup>
          <FormControlLabel control={<Checkbox disabled checked={boostInventory} color="default"/>}
          label={'Boost Redemptions'}
           />

        </FormGroup>
      </Stack>
      <FormGroup>
    { rangeValue &&  <Box sx={{ background: "#FFFDE7",
                height: '50px',
                display: 'flex',
                alignItems: 'center',
                margin: '8px'}}>
                <Typography component="span" sx={{fontWeight: 700, display: 'flex'}}>
                  Value range available:


                   <Typography sx={{ marginLeft: 2}}>

                      {rangeValue}
                    </Typography>

                </Typography>
      </Box>}
      {
        rangeValue &&  selectedCard && selectedCountryDetails &&
         <Stack spacing={2} direction='row'>
          <NumberInput
            title={'Value Desired per card'}
            value={desiredAmount}
            disableSteppers
            min={selectedCard.min}
            max={selectedCard.max}
            helperText={selectedCountryDetails.currency}
            onChangeCustom={onChangeCardAmount}

            />
            <NumberInput
              title={'Keep a in-house stock of'}
              value={cardCount}
              disableSteppers
              helperText='Cards'
              min={0}
              onChangeCustom={onChangeCardCount}


              />
              <NumberInput
                title={'Confirm value'}
                value={cardCountConfirm}
                disableSteppers
                helperText='Cards'
                min={0}
                onChangeCustom={onChangeCardCountConfirm}
                />
        </Stack>
      }
      { rangeValue && cardCountConfirm
        ? confirmed
          ? cardCountConfirm * desiredAmount < SPENDING_COST_THRESHOLD
            ? <Box sx={{ background: "#FFF9C4",
                    height: '50px',
                    display: 'flex',
                    alignItems: 'center',
                    margin: '8px'}}>
                    <Typography component="span" sx={{display: 'flex'}}>
                      <Box sx={{height: '20px', display: 'flex'}}><CreditCardIcon sx={{marginLeft: 1}}/></Box>

                       <Typography sx={{ marginLeft: 2}}>

                          The initial cost to buy this cards is
                        </Typography>
                        <Typography sx={{ marginLeft: 2, fontWeight: 700}}>

                           {cardCountConfirm * desiredAmount}
                         </Typography>
                    </Typography>
          </Box>
          :  <AttentionOverCost count={cardCountConfirm} amount={desiredAmount} currency={selectedCountryDetails.currency} />

        :<Box sx={{ background: "#ffebee",
                  height: '50px',
                  display: 'flex',
                  alignItems: 'center',
                  margin: '8px'}}>
                  <Typography component="span" sx={{display: 'flex'}}>
                     <Box sx={{height: '20px', display: 'flex'}}><ReportProblemIcon color={'error'} sx={{marginLeft: 1}}/></Box>
                     <Typography sx={{ marginLeft: 2}}>

                        In-house target values don’t match.
                      </Typography>

                  </Typography>
        </Box>
      :<></>}
        <FormControlLabel control={<Checkbox checked={enableConfirm} color="default" disabled={!confirmed && !enableConfirm}/>}
        label={`I confirm we will offer this card via Boost Redemptions and
maintain the in-house stock informed.`}
         onChange={onCheckToggle}/>

      </FormGroup>
    </Stack>
  )
}
