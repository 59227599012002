import React, { useEffect, useState } from 'react';
import {
  TableCell,
  TableRow,
  IconButton
} from '@mui/material'
import {tableCellClasses} from '@mui/material/TableCell'
import { styled } from '@mui/material/styles';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import ChallengesActivityStatusCell from "./ChallengesActivityGroupStatusCell";
import ChallengeActivityTable from "./ChallengeActivityTable";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import FormattedDatesDisplay from "./Shared/FormattedDatesDisplay";
import Tooltip from "@mui/material/Tooltip";


const ChallengeActivityGroupRow = (props) => {
  const [ detailsOpen, setDetailsOpen ] = useState(false)
  const [ group, setGroup ] = useState(props.group)

  useEffect(()=>{
    setGroup(props.group)
  })

  const bgYellow = '#f6f1bd'

  const ChallengeTableCell = styled(TableCell)(({theme})=>({
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
      minWidth: "200px",
      textAlign: "center"
    },
  }))

  const prizeStyle = {
    borderRadius: 15,
    margin: '0px 0px',
    padding: '3px 0px',
    backgroundColor: 'rgb(214 214 214)',
    width: "70%",
    marginLeft: "15%"
  }

  function selectBackgroundColor(){
    if(detailsOpen){
      return bgYellow
    } else {
      return "initial"
    }
  }

  function isValidTimeZone(tz) {
    try {
      Intl.DateTimeFormat(undefined, { timeZone: tz });
      return true;
    } catch (ex) {
      return false;
    }
  }

  function formatDate(epoch, timezone = "America/New_York"){
    let finalTimezone = timezone
    if(!isValidTimeZone(timezone)){
      finalTimezone = timezone
    }

    if(isNaN(epoch)){
      return
    }

    try {
      let date = new Date(new Date(epoch*1000).toGMTString())
      return date.toLocaleString("en-US", { timeZone: finalTimezone, hour12: true })
    } catch(err) {
      console.error(err)
      return new Date(epoch*1000).toGMTString()
    }
  }

  function dateToNextState() {
    let epoch = 0
    for (const activity of group.activities) {
      let unlocking = ["Inv. Locked", "Unlocking"].includes(group.status)
      if(unlocking && activity.goalType === "install_challenge"){
        const secondsToAdd = activity.sessionInvitedDays * 24 * 60 * 60
        const baseEpoch = activity.groupStartDate + secondsToAdd
        const date = new Date(baseEpoch * 1000)
        const nextMidnight = new Date(date.toLocaleString('en-US', { timeZone: activity.timezone, hour12: false }));
        nextMidnight.setHours(24, 0, 0, 0)
        epoch = Math.floor(nextMidnight.getTime() / 1000)
        break;
      } else if (!unlocking && activity.goalType !== "install_challenge"){
        epoch = activity.dateToNextState
        break;
      }
    }
    return epoch
  }

  function timingLabel() {
    return group.status === "Inv. Locked" ? "Unlocks at:" : "Expires at:"
  }

  function toggleActivityDetails(){
    if (detailsOpen){
      setDetailsOpen(false)
    } else {
      setDetailsOpen(true)
    }
  }

  return(
    <>
      <TableRow style={{backgroundColor: selectBackgroundColor()}} key={1}>
        <ChallengeTableCell style={{minWidth:0, maxWidth: '30px', paddingRight: '10px'}}>
          <Tooltip title={`Challenge ID: ${group.challengeId}`}>
            <ViewModuleIcon style={{color: "grey", maxWidth: '30px'}}></ViewModuleIcon>
          </Tooltip>
        </ChallengeTableCell>

        <ChallengeTableCell style={{textAlign: "left"}}>
          <FormattedDatesDisplay
            epoch={group.groupStartDate}
            timezone={group.timezone}
            label={timingLabel()}
            labelEpoch={dateToNextState()}
          />
        </ChallengeTableCell>

        <ChallengeTableCell>
          <p>Boost</p>
        </ChallengeTableCell>

        <ChallengeTableCell>
          <p>{group.mustCompleteLabel}</p>
        </ChallengeTableCell>

        <ChallengeTableCell align="center">
          <p style={prizeStyle}>{group.prizeLabel}</p>
        </ChallengeTableCell>

        <ChallengesActivityStatusCell
          status={group.status}
        />

        <ChallengeTableCell style={{textAlign: "center", minWidth: "150px"}}>
          { group.completionDate &&
            <FormattedDatesDisplay
              epoch={group.groupStartDate}
              timezone={group.timezone}
              label={timingLabel()}
              labelEpoch={dateToNextState()}
            />
          }

        </ChallengeTableCell>

        <ChallengeTableCell style={{minWidth: "50px"}}>
          <IconButton
            onClick={toggleActivityDetails}
          >
            {detailsOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </ChallengeTableCell>
      </TableRow>

      <ChallengeActivityTable
        detailsOpen={detailsOpen}
        activities={group.activities}
        group={group}
      />
    </>
  )

}

export default ChallengeActivityGroupRow